import React, { useContext, useState } from "react";

//Style
import "./LocationSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const LocationSection = () => {
  const { location } = useContext(NavbarContext);

  const [view, setView] = useState("project");

  const handleView = (e) => {
    setView(e.target.name);
    if (e.target.name === "project" && view === "project") {
      window.open(
        "https://6541151f0d3bf40a44cbca59--splendorous-genie-018573.netlify.app/",
        "_blank"
      );
    }
  };

  return (
    <section ref={location} className="location-section" id="location">
      <h1 className="location-title">Beneficios <strong>De La Ubicación</strong></h1>
      <div className="btn-map-selector">
        <button
          onClick={(e) => handleView(e)}
          name="project"
          className="btn-map"
          id="vista-aerea"
        >
          Vista Aérea
        </button>
        <button
          name="video"
          className="btn-map"
          onClick={(e) => handleView(e)}
          id="boton-video"
        >
          Video
        </button>
        <button
          onClick={(e) => handleView(e)}
          name="map"
          className="btn-map"
          id="boton-mapa"
        >
          Ubicación
        </button>
      </div>
      {view === "project" && (
        <div className="map-container">
          <div className="location-3d-container">
            <iframe
              title="vista-aerea"
              src="https://6541151f0d3bf40a44cbca59--splendorous-genie-018573.netlify.app"
              frameborder="0"
              width={"100%"}
              height={"100%"}
            ></iframe>
          </div>
        </div>
      )}
      {view === "video" && (
        <div
          className="map-container"
        >
          <a
            href="https://www.waze.com/es-419/live-map/directions?to=ll.5.026095%2C-73.993199"
            className="waze-btn"
            target="_blank"
            title="Waze"
          >
            <div className="text-btn-container">
              <p>Visita nuestra</p>
              <strong>sala de ventas</strong>
            </div>
            <img
              src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/logo-waze.webp"
              rel="preconnect"
              alt=""
              className="waze-icon"
            />
          </a>
          <div className="location-video-container">
            <video
              className="location-video"
              autoPlay
              loop
              muted
              playsInline
              controls
            >
              <source
                src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/videos/home-propdevs.mp4"
                rel="preconnect"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )}
      {view === "map" && (
        <div className="map-container">
          <div
            className="mapouter"
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <div
              className="gmap_canvas"
              style={{ width: "100%", height: "100%" }}
            >
              <iframe
                width={"100%"}
                height={"100%"}
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                id="gmap_canvas"
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Proyecto%20de%20vivienda%20VIS%20Club%20Residencial%20Mirador%20de%20La%20Sabana%20Zipaquira+(Proyecto%20de%20vivienda%20VIS%20Club%20Residencial%20Mirador%20de%20La%20Sabana)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default LocationSection;
