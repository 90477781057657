import React from 'react'

//Components
import HeaderThanksPage from '../../components/HeaderThanksPage/HeaderThanksPage'
import BodyThanksPage from '../../components/BodyThanksPage/BodyThanksPage'
import FooterThanksPage from '../../components/FooterThanksPage/FooterThanksPage'

const ThanksScreen = () => {
  return (
    <>
        <HeaderThanksPage />
        <BodyThanksPage />
        <FooterThanksPage />
    </>
  )
}

export default ThanksScreen