import React from "react";

//Style
import "./MobileFormButton.css";

const MobileFormButton = () => {
  return (
    <div className="btn-form-mb-container">
      <a href="#client-zone" className="anchor-btn-form" title="Formulario">
        <img
          className="form-btn-icon"
          rel="preconnect"
          src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/form-mobile-icon.webp"
          srcset="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/form-mobile-icon.webp 32w"
          sizes="(max-width: 1024px) 32px"
          alt=""
        />
      </a>
    </div>
  );
};

export default MobileFormButton;
