import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./BannerHome.css";

const BannerHome = ({ imgs, heightBanner }) => {
  return (
    <section className="banner-section">
      <Carousel
        images={imgs}
        style={{
          width: "100%",
          height: heightBanner,
          objectFit: "cover"
        }}
        shouldLazyLoad={true}
        hasTransition={true}
        hasLeftButton={false}
        hasRightButton={false}
        hasIndexBoard={false}
        hasMediaButton={false}
        shouldMaximizeOnClick={false}
        hasSizeButton={false}
        hasLeftButtonAtMax={false}
        hasRightButtonAtMax={false}
        hasSizeButtonAtMax={false}
        shouldSwipeOnMouse={false}
        objectFit={"fill"}
        hasThumbnails={false}
        hasThumbnailsAtMax={true}
        canAutoPlay={true}
        isAutoPlaying={true}
        autoPlayInterval={6000}
      />
    </section>
  );
};

export default BannerHome;
