import React, { useEffect, useState } from "react";

//Components
import HomeSection from "../HomeSection/HomeSection";
import BenefitSection from "../BenefitSection/BenefitSection";
import AptoSection from "../AptoSection/AptoSection";
import CommonAreasSection from "../CommonAreasSection/CommonAreasSection";
import LocationSection from "../LocationSection/LocationSection";
import EndGallerySection from "../EndGallerySection/EndGallerySection";
import FundingSection from "../FundingSection/FundingSection";
import WhatsappBtn from "../WhatsappBtn/WhatsappBtn";
import MobileFormButton from "../MobileFormButton/MobileFormButton";
import InsideViewSection from "../InsideViewSection/InsideViewSection";
import BenefitDeco from "../BenefitDeco/BenefitDeco";

const Body = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <WhatsappBtn />
      <MobileFormButton />
      <HomeSection wide={wide} />
      <BenefitDeco />
      <BenefitSection wide={wide} />
      <CommonAreasSection wide={wide} />
      <InsideViewSection />
      <AptoSection wide={wide} />
      <LocationSection />
      <EndGallerySection wide={wide} />
      <FundingSection wide={wide} />
    </>
  );
};

export default Body;
