import React from 'react';

//Style
import './FooterThanksPage.css';

const FooterThanksPage = () => {
  return (
    <section className='footer-thanks-container'>
        <img className='footer-thanks-logo-otacc' src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/otacc-footer-logo.webp" rel="preconnect" alt="" />
        <div className="more-prjs-container">
            <img src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/prjs-logos-sab.webp" rel="preconnect" alt="" className="prjs-logos" />
            <button onClick={() => {window.location.href='https://corporativo.compensar.com/vivienda/tyba-reservado'}} className="show-more-btn" title='Ver Proyecto Tyba'>Ver</button>
        </div>
    </section>
  )
}

export default FooterThanksPage