import React, { useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import data from "../../json/horGalleryImg.json";

//Style
import "./EndGallerySection.css";

//Components
import GalleryHorizontal from "../GalleryHorizontal/GalleryHorizontal";

//Context
import { GalleryContext } from "../../context/GalleryContext";

const EndGallerySection = ({ wide }) => {
  const [openView, setOpenView] = useState(false);

  return (
    <section className="gallery-hor-section">
      <img className="bg-img" src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/white-wall-concrete.webp" rel="preconnect" alt="" loading="lazy" />
      <div className="gallery-hor-subcontainer">
        <h1 className="gh-title">
          Proyecto VIS <strong>Aplica Subsidio</strong>
        </h1>
        <GalleryContext.Provider value={{ setOpenView }}>
          {openView && (
            <div className="fs-gallery-container">
              <div
                onClick={() => setOpenView(false)}
                className="reset-values-gallery"
              ></div>
              <Carousel
                images={data.data}
                isMaximized="true"
                hasIndexBoard="false"
                hasMediaButton="false"
                index={3}
              />
            </div>
          )}
          <div className="bg-color"></div>
          <GalleryHorizontal open={openView} wide={wide} />
          <div className="carousel-mobile-hor">
            <Carousel
              images={data.data}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              isAutoPlaying={true}
              hasDotButtons={"bottom"}
              hasLeftButton={false}
              hasRightButton={false}
              hasMediaButton={false}
              hasIndexBoard={false}
              hasThumbnails={false}
              hasSizeButton={"topRight"}
              transitionSpeed={0.1}
              hasSizeButtonAtMax={"bottomRight"}
              hasThumbnailsAtMax={true}
              hasLeftButtonAtMax={"centerLeft"}
              hasRightButtonAtMax={"centerRight"}
            />
          </div>
        </GalleryContext.Provider>
      </div>
    </section>
  );
};

export default EndGallerySection;
