import React, { useContext, useState } from "react";

//Style
import "./FundingSection.css";

//Components
import Form from "../Form/Form";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const FundingSection = ({ wide }) => {
  const { funding } = useContext(NavbarContext);

  const [deployForm, setDeployForm] = useState(false);

  window.onscroll = function () {
    if (window.scrollY >= 3400) {
      setDeployForm(true);
    } else {
      setDeployForm(false);
    }
  };

  return (
    <section ref={funding} className="funding-section" id="client-zone">
      <img className="funding-bg-img" src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/chair-wall-bg.webp" rel="preconnect" alt="" loading="lazy" />
      <div
        className={
          wide > 1023 && deployForm
            ? "funding-info-supercontainer changed-position"
            : "funding-info-supercontainer"
        }
      >
        <div className="form-deployed-container">
          <Form deploy={deployForm} wide={wide} />
        </div>
      </div>
    </section>
  );
};

export default FundingSection;
