import React, { useContext, useState } from "react";

//Data
import dataHorGalleryThumbnails from "../../json/horGalleryThubnails.json";

//Style
import "./GalleryHorizontal.css";

//Context
import { GalleryContext } from "../../context/GalleryContext";

const GalleryHorizontal = ({ wide }) => {
  const [img, setImg] = useState(dataHorGalleryThumbnails.data[0].src || "");

  const { setOpenView } = useContext(GalleryContext);

  return (
    <div className="gallery-container">
      <div className="img-container">
        <img src={img} alt="" className="gallery-img" />
        <div className="btns-container">
          <a
            href="https://my.matterport.com/show/?m=Tctvxu8x1aF"
            target="_blank"
            className="btn-gallery"
            title="Tour 3D"
          >
            Tour 3D
          </a>
          <a
            href="https://www.google.com/maps/place/Proyecto+de+vivienda+VIS+Club+Residencial+Mirador+de+La+Sabana/@5.0259926,-73.9939366,18z/data=!4m15!1m8!3m7!1s0x8e406f8c1224572d:0xd91c50cb7bb140d7!2sProyecto+de+vivienda+VIS+Club+Residencial+Mirador+de+La+Sabana!8m2!3d5.0259827!4d-73.9931785!10e1!16s%2Fg%2F11l2x4q8_0!3m5!1s0x8e406f8c1224572d:0xd91c50cb7bb140d7!8m2!3d5.0259827!4d-73.9931785!16s%2Fg%2F11l2x4q8_0?hl=es&entry=ttu"
            target="_blank"
            className="btn-gallery"
            title="Ubicación"
          >
            Como Llegar
          </a>
        </div>
      </div>
      <div className="thumbs-container">
        {dataHorGalleryThumbnails.data.slice(1).map((item, i) => {
          if (wide <= 1023) {
            if (i > 1) {
              return (
                <div style={{ display: "none" }} key={i} className="thumb">
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                    width={240}
                    height={136}
                  />
                </div>
              );
            } else {
              return (
                <div key={i} className={`thumb ${i === 1 && "last"}`}>
                  {i == 1 && (
                    <div className="more-btn-container">
                      <p className="img-total">
                        + {dataHorGalleryThumbnails.data.length - 1}
                      </p>
                      <button
                        onClick={() => setOpenView(true)}
                        className="more-btn"
                      >
                        Ver más
                      </button>
                    </div>
                  )}
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            }
          }
          if (i > 3) {
            return (
              <div style={{ display: "none" }} key={i} className="thumb">
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
            );
          } else {
            return (
              <div key={i} className={`thumb ${i === 3 && "last"}`}>
                {i == 3 && (
                  <div className="more-btn-container">
                    <p className="img-total">
                      + {dataHorGalleryThumbnails.data.length - 3}
                    </p>
                    <button
                      onClick={() => setOpenView(true)}
                      className="more-btn"
                    >
                      Ver más
                    </button>
                  </div>
                )}
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                  loading="lazy"
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default GalleryHorizontal;
