import React from "react";

//Style
import "./InsideViewSection.css";

const InsideViewSection = () => {
  return (
    <section className="inside-view-section">
      <div className="inside-view-subcontainer">
        <iframe
          title="vista-aerea"
          src="https://my.matterport.com/show/?m=Tctvxu8x1aF"
          frameborder="0"
          width={"100%"}
          height={"100%"}
        ></iframe>
      </div>
    </section>
  );
};

export default InsideViewSection;
