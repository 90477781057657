import React from 'react'

const BenefitDeco = () => {
  return (
    <div className='benefit-deco-container'>
        <img src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/benefit-deco-img.webp" alt="" rel="preconnect" />
    </div>
  )
}

export default BenefitDeco