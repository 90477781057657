import React from 'react';

//Style
import './HeaderThanksPage.css';

const HeaderThanksPage = () => {
  return (
    <section className='header-thanks-container'>
        <img className='header-logo' src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/logo.webp" rel="preconnect" alt="Logo del proyecto inmobiliario Mirador de la Sabana" />
    </section>
  )
}

export default HeaderThanksPage