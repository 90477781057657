import React, { useContext, useRef } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import data from "../../json/carousel.json";

//Style
import "./CommonAreasSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const CommonAreasSection = ({ wide }) => {
  const { commonZones } = useContext(NavbarContext);

  const gallery = useRef();

  return (
    <section
      ref={commonZones}
      className="common-areas-section"
      id="common-zones"
    >
      <img className="bg-img" src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/white-wall-bg.webp" alt="" loading="lazy" rel="preconnect" />
      <div className="common-areas-subcontainer">
        <div className="title-container">
          <h1 className="title">Zonas <strong>Comunes</strong></h1>
        </div>
        <div className="frame-area">
          <div className="ca-gallery-container">
            <Carousel
              ref={gallery}
              images={data.data}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              shouldLazyLoad={true}
              hasTransition={true}
              hasLeftButton={"centerLeft"}
              hasRightButton={"centerRight"}
              hasIndexBoard={false}
              hasMediaButton={false}
              shouldMaximizeOnClick={false}
              hasSizeButton={false}
              hasLeftButtonAtMax="centerLeft"
              hasRightButtonAtMax="centerRight"
              hasSizeButtonAtMax="topRight"
              shouldSwipeOnMouse={true}
              objectFit={"cover"}
              hasThumbnails={true}
              hasThumbnailsAtMax={true}
              canAutoPlay={true}
              isAutoPlaying={true}
              autoPlayInterval={2500}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonAreasSection;
