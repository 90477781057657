import React from "react";

//Components
import Navbar from '../../components/Navbar/Navbar'
import Body from '../../components/Body/Body'
import Footer from '../../components/Footer/Footer'

const HomeScreen = () => {
  return (
    <>
      <Navbar />
      <Body />
      <Footer />
    </>
  );
};

export default HomeScreen;
