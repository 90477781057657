import React, { useState, useEffect } from "react";

//Style
import "./WhatsappBtn.css";

const WhatsappBtn = () => {
  const [hoverWa, setHoverWa] = useState(false);

  const [openWaForm, setOpenWaForm] = useState(false);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [checkedValue, setCheckedValue] = useState(false);

  const [userWaInfo, setUserWaInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    "00NRQ000000fTu5": "",
    "00NRQ000001z2Qv": "",
    checked: false,
  });

  const [selectedOpt, setSelectedOpt] = useState(
    <option value="" selected>
      --Ninguno--
    </option>
  );

  const params = new URLSearchParams(window.location.search);
  const source = params.get("utm_source");
  const campaign = params.get("utm_campaign");

  useEffect(() => {
    if (!source || source === "") {
      setSelectedOpt(
        <option value="Otra Fuente" selected>
          Otra Fuente
        </option>
      );
    } else {
      setSelectedOpt(
        <option value={source} selected>
          {source}
        </option>
      );
    }
  }, []);

  const handleWaForm = (e) => {
    e.target.name === "checked" && setCheckedValue(!checkedValue);
    const valueCheck = e.target.name === "checked" && e.target.checked;

    if (e.target.name === "first_name" || e.target.name === "last_name") {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailPattern.test(e.target.value)) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "00NRQ000000fTu5") {
      e.target.value = e.target.value.replace(/\D/g, "");

      if (e.target.value.trim() !== "") {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "00NRQ000001z2Qv") {
      if (e.target.value.trim() !== "") {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "mobile") {
      e.target.value = e.target.value.replace(/\D/g, "");
      if (/^\s*3/.test(e.target.value.trim())) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    setUserWaInfo({
      ...userWaInfo,
      [e.target.name]: e.target.value,
      checked: valueCheck,
    });

    if (
      userWaInfo.first_name &&
      userWaInfo.first_name !== "" &&
      userWaInfo.last_name &&
      userWaInfo.last_name !== "" &&
      userWaInfo.email &&
      userWaInfo.email !== "" &&
      userWaInfo.mobile &&
      userWaInfo.mobile !== "" &&
      userWaInfo["00NRQ000000fTu5"] &&
      userWaInfo["00NRQ000000fTu5"] !== "" &&
      userWaInfo["00NRQ000001z2Qv"] &&
      userWaInfo["00NRQ000001z2Qv"] !== "" &&
      valueCheck !== false
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  // const handleSubmit = (e) => {
  //   const apiUrl = "https://crediseguro-back.click/send";
  //   const data = {
  //     destinatario: ["carlosbuitrago@otacc.com", "cwilches@otacc.com"],
  //     asunto: `Formulario Landing Mirador de la Sabana - ${userWaInfo["00NRQ000000fTu5"]}`,
  //     code: `
  //       <div>
  //         <h1>Hola!!</h1>
  //         <p>Has recibido información de un potencial cliente desde la landing de <strong>Mirador de la Sabana</strong></p>
  //         <p>Aqui podras ver la información del usuario:</p>
  //         <ul>
  //           <li>Nombres y Apellidos: <strong>${userWaInfo.first_name} ${
  //       userWaInfo.last_name
  //     }</strong></li>
  //           <li>Numero de Documento: <strong>${
  //             userWaInfo["00NRQ000000fTu5"]
  //           }</strong></li>
  //           <li>Correo eléctronico: <strong>${userWaInfo.email}</strong></li>
  //           <li>Celular: <strong>${userWaInfo.mobile}</strong></li>
  //           <li>Ingresos Mensuales: <strong>${
  //             userWaInfo["00NRQ000001z2Qv"]
  //           }</strong></li>
  //           <li>Fuente: <strong>${source ? source : "Otra"}</strong></li>
  //           <li>Campaña: <strong>${campaign ? campaign : "Otra"}</strong></li>
  //         </ul>
  //       </div>
  //     `,
  //   };

  //   const options = {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   };

  //   fetch(apiUrl, options)
  //     .then(() => {
  //       setCheckedValue(false);
  //       setOpenWaForm(false);
  //       setUserWaInfo({
  //         first_name: "",
  //         last_name: "",
  //         email: "",
  //         mobile: "",
  //         "00NRQ000000fTu5": "",
  //         "00NRQ000001z2Qv": "",
  //         checked: false,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  const handleResetWaForm = () => {
    setOpenWaForm(false);
  };

  return (
    <>
      <div
        className={`form-wa-container ${
          openWaForm ? "open-wa-form" : "close-wa-form"
        }`}
      >
        <form
          onChange={handleWaForm}
          // onSubmit={handleSubmit}
          className="form-wa-input-container"
          style={
            disabledBtn ? { cursor: "not-allowed" } : { cursor: "pointer" }
          }
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
        >
          <input type="hidden" name="oid" value="00D4W000008GmFk" />
          <input
            style={{ display: "none" }}
            id="00NRQ000000CElZ"
            name="00NRQ000000CElZ"
            type="checkbox"
            value="1"
            checked
          />
          <input
            type="hidden"
            name="retURL"
            value={`https://api.whatsapp.com/message/JASO4CUJ37KUH1?autoload=1&app_absent=0`}
          />
          <input
            id="00NRQ000000Hrb7"
            maxlength="50"
            name="00NRQ000000Hrb7"
            size="20"
            type="text"
            value={"Whatsapp"}
            style={{ display: "none" }}
          />
          <input
            id="00NRQ000000BQjx"
            maxLength="50"
            name="00NRQ000000BQjx"
            size="20"
            type="text"
            style={{ display: "none" }}
            className="hide-input"
            value={campaign ? campaign : "Otro"}
          />
          <input
            id="00NRQ000002QCyr"
            maxlength="255"
            name="00NRQ000002QCyr"
            size="20"
            type="text"
            value={window.location.href}
            style={{display: "none"}}
          />
          <select
            style={{ display: "none" }}
            id="00N4W00000ScMLv"
            name="00N4W00000ScMLv"
            title="Fuente"
            className="hide-select"
          >
            {selectedOpt}
          </select>
          <label htmlFor="first_name">
            <input
              value={userWaInfo.first_name}
              className="input-form-wa"
              type="text"
              placeholder="Nombre*"
              autoComplete="off"
              id="first_name"
              maxLength="40"
              name="first_name"
              size="20"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="last_name">
            <input
              name="last_name"
              value={userWaInfo.last_name}
              className="input-form-wa"
              type="text"
              placeholder="Apellido*"
              autoComplete="off"
              id="last_name"
              maxLength="80"
              size="20"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="email">
            <input
              name="email"
              value={userWaInfo.email}
              className="input-form-wa"
              type="email"
              placeholder="Correo eléctronico*"
              autoComplete="off"
              id="email"
              maxLength="80"
              size="20"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="00NRQ000000fTu5">
            <input
              id="00NRQ000000fTu5"
              name="00NRQ000000fTu5"
              ize="20"
              type="text"
              onChange={(e) => handleWaForm(e)}
              value={userWaInfo["00NRQ000000fTu5"]}
              placeholder="No. de Documento*"
              className="input-form-wa"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="mobile">
            <input
              name="mobile"
              value={userWaInfo.mobile}
              className="input-form-wa"
              type="text"
              placeholder="Celular*"
              maxLength={10}
              autoComplete="off"
              id="mobile"
              size="20"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="00NRQ000001z2Qv">
            <select
              name="00NRQ000001z2Qv"
              id="salary"
              className="input-form-wa select"
            >
              <option value="">--Ingresos Mensuales--</option>
              <option value="Menor a $2.000.000">Menor a $2.000.000</option>
              <option value="Entre $2.000.000 y $4.000.000">
                Entre $2.000.000 y $4.000.000
              </option>
              <option value="Entre $4.000.001 y $6.000.000">
                Entre $4.000.001 y $6.000.000
              </option>
              <option value="Entre $6.000.001 y $8.000.000">
                Entre $6.000.001 y $8.000.000
              </option>
              <option value="Entre $8.000.001 y $10.000.000">
                Entre $8.000.001 y $10.000.000
              </option>
              <option value="Entre $10.000.001 y $15.000.000">
                Entre $10.000.001 y $15.000.000
              </option>
              <option value="Mayor a $15.000.000">Mayor a $15.000.000</option>
            </select>
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label className="label-advise">
            <input
              checked={checkedValue}
              name="checked"
              type="checkbox"
              className="checkbox-input"
            />{" "}
            <a
              target="_blank"
              className="anchor-check"
              href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
              rel="preconnect"
            >
              Acepto la política de tratamientos de datos. <p>Ver más.</p>
            </a>
          </label>
          <button
            onClick={handleResetWaForm}
            disabled={disabledBtn}
            type="submit"
            className="form-wa-btn"
          >
            Contactar
          </button>
        </form>
      </div>
      <div className="wa-btn-container">
        <a
          id="Formulario_Whatsapp"
          onMouseEnter={() => setHoverWa(true)}
          onMouseLeave={() => setHoverWa(false)}
          onClick={() => setOpenWaForm(!openWaForm)}
          className="wa-btn-icon-container"
          title="Whatsapp"
        >
          <img
            className="wa-icon"
            src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/whatsapp-icon.svg"
            rel="preconnect"
            alt=""
          />
        </a>
      </div>
    </>
  );
};

export default WhatsappBtn;
